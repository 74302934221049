export function useDateInput() {
  const { parseDate } = useDate()
  const { locale } = useI18n()

  const dateFormats: Record<string, string> = {
    en: 'MM/DD/YYYY',
    ru: 'DD.MM.YYYY',
    es: 'DD/MM/YYYY',
    pt: 'DD/MM/YYYY',
    default: 'MM/DD/YYYY',
  }

  const dateFormat = computed(
    () => dateFormats[locale.value] ?? dateFormats.default,
  )
  const dateMask = computed(() =>
    parseDate().format(dateFormat.value).replace(/[0-9]/g, '#'),
  )

  function parseDateInput(input: string) {
    return parseDate(input.trim(), dateFormat.value, locale.value, true)
  }

  return {
    dateMask,
    dateFormat,
    parseDateInput,
  }
}
