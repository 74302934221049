import { useSettingsStore } from '@st/core/stores/useSettingsStore'

export function useVerificationCountryList() {
  const { settings } = storeToRefs(useSettingsStore())
  const { countries } = useCountries()

  const allowedCountries = computed(
    () =>
      new Set(
        settings.value?.frontAfterRegistrationCountries?.map((country) =>
          country.toLowerCase(),
        ) ?? [],
      ),
  )

  return computed(() =>
    countries.value
      .filter((country) => allowedCountries.value.has(country.code))
      .map((country) => ({
        key: country.code,
        name: country.name,
        icon: country.icon,
      })),
  )
}
